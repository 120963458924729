<template>
    <Table v-if="table" :options="table.configuration" :replacementStrings="replacementStrings"></Table>

</template>

<script>
import Table from '@/components/shared/Table';
import { data } from "@/shared";

require('@/assets/css/shared/table/table_variables_standard.scss');

export default {
    name: 'TestAccordion',
    components: {Table},
    data: function() {
        return {
            replacementStrings: { '%ANNUAL_SALARY%': '$24,000'},
            table: null
        }
    },
    async mounted() {
        this.table = await data.getMediaProperty(407);
    },
  }
</script>